<template>
<AppLayout>
    <template v-slot:appContent>
        <div>
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Customers/View</h4>
                <div>
                    <router-link :to="{...previousRoute}">
                        <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
                    </router-link>
                </div>
            </div>
            <div class="content-body">
                <section class="page-user-profile">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-block d-sm-flex mb-2 align-items-center">
                                        <div class="mr-2 position-relative">
                                            <img :src="user.avatar" alt="users avatar" class="users-avatar-shadow rounded-circle" height="120" width="120">
                                            <div class="position-absolute update-image-avatar d-flex align-items-center justify-content-center" @click="openImageUpdateModal()">
                                                <i class='bx bxs-camera' data-toggle="modal" data-target="#updateAvatarModal"></i>
                                            </div>
                                        </div>
                                        <div class="media-body ">
                                            <h4 class="media-heading">
                                                <span class="d-inline-flex justify-content-center">
                                                    <i :class="type === 'Business' ? 'bx bxs-business' : 'bx bxs-home'" style="font-size: 2rem">
                                                    </i>
                                                    <span>&nbsp;{{ userFullName }}</span>
                                                    <button :disabled="!can('customer-update')" :title="can('customer-update') ?
                                      (status === 'Active' ? 'Active | Click to Inactive' : 'Inactive | Click to Active')
                                      : 'Update Forbidden'" @click="updateCustomerStatus(status === 'Active' ? 0 : 1)" class="m-0 p-0 bg-transparent border-0">
                                                        <i class='bx' :class="status === 'Active'
                                    ? 'bxs-checkbox-checked text-success'
                                    : 'bx-checkbox-square text-warning'" style="font-size: 2rem;">
                                                        </i>
                                                    </button>
                                                </span>
                                            </h4>
                                            <div class="d-inline-flex justify-content-center">
                                                <span class="h5">Newsletter Subscription: &nbsp;</span>
                                                <button :disabled="!can('customer-update')" @click="updateCustomerNewsletterSubscriptionStatus(newsletterSubscription === 'Subscribed' ? 0 : 1)" :title="can('customer-update')
                                      ? (newsletterSubscription === 'Subscribed' ? 'Subscribed | Click to Unsubscribe' : 'Unsubscribed | Click to Subscribe')
                                      : 'Update Forbidden'" class="m-0 p-0 bg-transparent border-0">
                                                    <i class='bx' :class="newsletterSubscription === 'Subscribed'
                                  ? 'bxs-bell-ring text-success'
                                  : 'bx-bell-off text-warning'" style="font-size: 1.5rem;"></i>
                                                </button>
                                            </div>
                                            <div class="h6">Referral Code: &nbsp;<span>{{ referralCode }}</span></div>
                                            <div class="h6">Credited Amount: &nbsp;<span>{{ creditedAmount?.amount }}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3 ">
                                    <div class="card ">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center mb-1">
                                                <div>
                                                    <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                                                        User information
                                                    </h5>
                                                </div>
                                                <div class="ml-1" style="cursor: pointer;">
                                                    <button :disabled="!can('customer-update')" :title="can('customer-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#userDataUpdateModal" class="btn single-edit-btn text-primary">
                                                        <i class='bx bx-edit-alt'></i></button>
                                                </div>
                                            </div>
                                            <ul class="list-unstyled">
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">First Name: </span>
                                                    <span>{{ user.firstName }}</span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">Last Name: </span>
                                                    <span>{{ user.lastName }}</span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Primary Contact Number: </span>
                                                    <span :class="!user.phoneNumber ? 'text-danger' : ''">
                                                        {{ user.phoneNumber ? user.phoneNumber : "Not provided" }}
                                                    </span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Email: </span>
                                                    <span :class="!user.email ? 'text-danger' : ''">
                                                        {{ user.email ? user.email : "Not provided" }}
                                                    </span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Date Of Birth: </span>
                                                    <span :class="!user.dateOfBirth ? 'text-danger' : ''">{{ userDateOfBirth }}</span>
                                                </li>
                                                <li class="mb-1">
                                                    <span class="text-bold-700 mb-1">Created at: </span>
                                                    <span>{{ createdAt }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-9 mb-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div>
                                                        <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                                                            {{ type === 'Business' ? "Business & Address Information" : "Address Information" }}
                                                        </h5>
                                                    </div>
                                                    <div class="ml-1" style="cursor: pointer;">
                                                        <button :disabled="!can('customer-update')" :title="can('customer-update') ? 'Update' : 'Update Forbidden'" data-toggle="modal" data-target="#addressUpdateModal" class="btn single-edit-btn text-primary">
                                                            <i class='bx bx-edit-alt'></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div v-if="address.street" class="row">
                                                    <div class="col-sm-12 col-12">
                                                        <h6>
                                                            <small class="text-muted">
                                                                {{ type === 'Business' ? 'CURRENT BUSINESS ADDRESS:' : 'CURRENT ADDRESS:' }}
                                                            </small>
                                                        </h6>
                                                        <p>{{ userFullAddress }}</p>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <p>No Address Assigned</p>
                                                </div>

                                                <div v-if="userAdditionalAddresses.length > 0" class="row mt-1">
                                                    <div class="col-sm-12 col-12">
                                                        <h6><small class="text-muted">{{ type === 'Business' ? 'ADDITIONAL BUSINESS ADDRESSES:' : 'ADDITIONAL ADDRESSES:' }} </small>
                                                        </h6>
                                                        <ol class="list-style-square ">
                                                            <li v-for="(address, index) in userAdditionalAddresses" :key="index">
                                                                <h6>{{ address.name }}
                                                                </h6>
                                                            </li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body">
                                            <div>
                                                <div class="d-flex align-items-center mb-1">
                                                    <div>
                                                        <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                                                            User Additional Information
                                                        </h5>
                                                    </div>
                                                    <div class="ml-1" style="cursor: pointer;">
                                                        <button :disabled="!can('customer-update')" :title="can('customer-update') ? 'Add' : 'Add Forbidden'" data-toggle="modal" data-target="#addUserAdditionalInfoModal" class="btn single-edit-btn text-primary">
                                                            <i class='bx bxs-book-add'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <h6><small class="text-muted">EMAIL ADDRESSES: </small></h6>
                                                        <ol v-if="userAdditionalEmails.length > 0" class="list-style-square ">
                                                            <li v-for="(userAdditionalEmail, index) in userAdditionalEmails" :key="index">
                                                                <h6>{{ userAdditionalEmail.valueAndLabel }}
                                                                    <span class="ml-1" style="cursor: pointer;">
                                                                        <button :disabled="!can('customer-update')" :title="can('customer-update') ? 'Update' : 'Update Forbidden'" @click="updateAdditionalInfoModalDetailsAssigning(userAdditionalEmail)" data-toggle="modal" data-target="#updateUserAdditionalInfoModal" class="single-edit-btn text-primary">
                                                                            <i class='bx bx-edit-alt'></i>
                                                                        </button>
                                                                    </span>
                                                                    <span class="ml-1" style="cursor: pointer;">
                                                                        <button :disabled="!can('customer-update')" :title="can('customer-update') ? 'Remove' : 'Remove Forbidden'" @click="removeAdditionalInfo(userAdditionalEmail)" class="single-edit-btn text-danger">
                                                                            <i class='bx bx-x'></i>
                                                                        </button>
                                                                    </span>
                                                                </h6>
                                                            </li>
                                                        </ol>
                                                        <div v-else>No data provided.</div>
                                                    </div>
                                                    <div class="col-6">
                                                        <h6><small class="text-muted">CONTACT NUMBERS: </small></h6>
                                                        <ol v-if="userAdditionalPhoneNumbers.length > 0" class="list-style-square ">
                                                            <li v-for="(userAdditionalPhoneNumber, index) in userAdditionalPhoneNumbers" :key="index">
                                                                <h6>{{ userAdditionalPhoneNumber.valueAndLabel }}
                                                                    <span class="ml-1" style="cursor: pointer;">
                                                                        <button :disabled="!can('customer-update')" :title="can('customer-update') ? 'Remove' : 'Remove Forbidden'" @click="updateAdditionalInfoModalDetailsAssigning(userAdditionalPhoneNumber)" data-toggle="modal" data-target="#updateUserAdditionalInfoModal" class="single-edit-btn text-primary">
                                                                            <i class='bx bx-edit-alt'></i></button>
                                                                    </span>
                                                                </h6>
                                                            </li>
                                                        </ol>
                                                        <div v-else>No data provided.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-body">
                                            <div>
                                                <div class="mb-1">
                                                    <div>
                                                        <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                                                            User Loyalty Points Information 
                                                        </h5>
                                                    </div>
                                                   
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <h6 >
                                                            <span class="text-muted mb-1">Available Points : </span>
                                                             <span> {{ loyaltyPointStatus?.total_points ?? 0 }} </span>
                                                        </h6>

                                                        <h6  v-if="loyaltyPointStatus?.current_group?.name">
                                                            <span class="text-muted mb-1">Current group : </span>
                                                             <span class="badge badge-pill "> {{ `${loyaltyPointStatus?.current_group?.name}` }} </span>
                                                        </h6>

                                                        <h6  v-if="loyaltyPointStatus?.current_group?.appointment_discount?.status">
                                                            <span class="text-muted mb-1">Appointment discount rate : </span>
                                                             <span class=" "> {{ loyaltyPointStatus?.current_group?.appointment_discount?.rate ?? 0 }}  </span>
                                                        </h6>
                                                        <h6  v-if="loyaltyPointStatus?.current_group?.appointment_discount?.status">
                                                            <span class="text-muted mb-1">Appointment  max discount amount : </span>
                                                             <span class=" "> {{  (loyaltyPointStatus?.current_group?.appointment_discount?.max_discount_amount) ?  (loyaltyPointStatus?.current_group?.appointment_discount?.max_discount_amount/100).toFixed(2)  : 0 }}  </span>
                                                        </h6>

                                                        <h6  v-if="loyaltyPointStatus?.current_group?.order_discount?.status">
                                                            <span class="text-muted mb-1">Order discount rate : </span>
                                                             <span class=" "> {{ loyaltyPointStatus?.current_group?.order_discount?.rate ?? 0 }}  </span>
                                                        </h6>
                                                        <h6  v-if="loyaltyPointStatus?.current_group?.order_discount?.status">
                                                            <span class="text-muted mb-1">Order  max discount amount : </span>
                                                             <span class=" "> {{  (loyaltyPointStatus?.current_group?.order_discount?.max_discount_amount) ?  (loyaltyPointStatus?.current_group?.order_discount?.max_discount_amount/100).toFixed(2)  : 0 }}  </span>
                                                        </h6>
                                                       
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <UserUpdateModal modal-name="userDataUpdateModal" :modal-usages-for="1" :user-id="user.id" :user-data="userData" @updatedUserData="updatedUserData" />
                <AddressUpdateModal modal-name="addressUpdateModal" address-update-for-model-name="customer" :model-id="id" :user-id="user.id" :selected-address-data="selectedAddressData" :business-status="type === 'Business'" @updateModalAddressData="updateModalAddressData" />
                <AddUserAdditionalInfoModal modal-name="addUserAdditionalInfoModal" :user-id="user.id" @userAdditionalInfoData="addUserAdditionalInfoData" />
                <UpdateUserAdditionalInfoModal modal-name="updateUserAdditionalInfoModal" :user-id="user.id" :type="UserAdditionalInfoUpdateOrCreateModalType" :user-additional-info-id="userAdditionalInfoIdForUpdate" :user-additional-info-existing-data-value="userAdditionalInfoExistingDataValue" :user-additional-info-existing-data-label="userAdditionalInfoExistingDataLabel" @userAdditionalInfoData="UpdateUserAdditionalInfoData" />

                <div class="" data-toggle="modal" data-target="#updateAvatarModal"></div>
                <UpdateAvatarModal v-on:getSingleView="uploadImage($event)" :previousImage="user.avatar" />
            </div>
        </div>
    </template>
</AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout'
import UserUpdateModal from "@/components/backEnd/modal/UserUpdateModal";
import AddressUpdateModal from "@/components/backEnd/modal/AddressUpdateModal";
import AddUserAdditionalInfoModal from "@/components/backEnd/modal/UserAdditionalInfoUpdateOrCreateModal";
import UpdateUserAdditionalInfoModal from "@/components/backEnd/modal/UserAdditionalInfoUpdateOrCreateModal";
import UpdateAvatarModal from "@/components/backEnd/modal/ImageUpdateModal";

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
    mapActions,
    mapGetters
} from "vuex";

export default {
    name: "CustomerView",

    mixins: [ShowToastMessage, Loader, Authorization],

    components: {
        AppLayout,
        UserUpdateModal,
        AddressUpdateModal,
        AddUserAdditionalInfoModal,
        UpdateUserAdditionalInfoModal,
        UpdateAvatarModal
    },
    data() {
        return {
            id: '',
            type: '',
            status: '',
            referralCode: '',
            newsletterSubscription: '',
            createdAt: '',
            user: {
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                dateOfBirth: '',
                avatar: '/app-assets/defaults/images/users/avatar.svg',

                addresses: [],
                userAdditionalPhoneNumbers: [],
                userAdditionalEmails: [],
            },
            address: {
                id: '',
                street: '',
                suburb: '',
                state: '',
                postCode: '',
                country: '',
                business: {
                    id: '',
                    name: '',
                },
            },
            referredBy: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
            },

            UserAdditionalInfoUpdateOrCreateModalType: null,
            userAdditionalInfoIdForUpdate: null,
            userAdditionalInfoExistingDataValue: '',
            userAdditionalInfoExistingDataLabel: '',

            getCustomerParams: {
                with_relation: ['address', 'address.business', 'user', 'user.media', 'user.addresses', 'user.addresses.business', 'user.userAdditionalEmails', 'user.userAdditionalPhoneNumbers', 'referredBy'],
            },
        }

    },
    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',
            customer: 'appCustomers/customer',

            loyaltyPointStatus: 'appLoyaltyPoints/loyaltyPointStatus',

             // credited payment amount
             creditedAmount : 'appPayments/creditedAmount'


        }),

        userFullName() {
            return `${this.user.firstName} ${this.user.lastName}`;
        },
        userFullAddress() {
            let businessName = this.address.business.name;
            let addressStreet = this.address.street;
            let addressSuburb = this.address.suburb;
            let addressState = this.address.state.toUpperCase();
            let addressPostCode = this.address.postCode;
            let addressCountry = this.address.country;
            return this.type === 'Business' ?
                `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
                `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
        },
        userAdditionalAddresses() {
            let userAddresses = this.user.addresses.filter(address => address.id !== this.address.id);

            return userAddresses.length > 0 ? this.type === 'Business' ?
                userAddresses.filter((address) => address.business && address.business.id).map((address) => {
                    let businessId = address.business.id;
                    let businessName = address.business && address.business.name ? address.business.name : '';
                    let addressId = address.id;
                    let addressStreet = address.street;
                    let addressSuburb = address.suburb;
                    let addressState = address.state.toUpperCase();
                    let addressPostCode = address.post_code;
                    let addressCountry = address.country;

                    return {
                        addressId: addressId,
                        businessId: businessId,
                        userId: this.user.id,
                        name: `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})`,

                    };
                }) :
                userAddresses.map((address) => {
                    let addressId = address.id;
                    let addressStreet = address.street;
                    let addressSuburb = address.suburb;
                    let addressState = address.state.toUpperCase();
                    let addressPostCode = address.post_code;
                    let addressCountry = address.country;

                    return {
                        addressId: addressId,
                        userId: this.user.id,
                        name: `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`
                    };
                }) : [];
        },

        userAdditionalEmails() {
            let userAdditionalEmails = this.user.userAdditionalEmails.filter(email => email.value !== this.user.email);

            return userAdditionalEmails.length > 0 ? userAdditionalEmails.map(userAdditionalEmail => {
                let id = userAdditionalEmail.id;
                let userAdditionalInfoValue = userAdditionalEmail.value;
                let userAdditionalInfoLabel = userAdditionalEmail.label;

                return {
                    id: id,
                    type: 0, // email type
                    value: userAdditionalInfoValue,
                    label: userAdditionalInfoLabel,
                    valueAndLabel: `${userAdditionalInfoValue} ${userAdditionalInfoLabel ? `(${userAdditionalInfoLabel})` : ''}`,
                }
            }) : [];
        },
        userAdditionalPhoneNumbers() {
            let userAdditionalPhoneNumbers = this.user.userAdditionalPhoneNumbers.filter(email => email.value !== this.user.phoneNumber);

            return userAdditionalPhoneNumbers.length > 0 ? userAdditionalPhoneNumbers.map(userAdditionalPhoneNumber => {
                let id = userAdditionalPhoneNumber.id;
                let userAdditionalInfoValue = userAdditionalPhoneNumber.value;
                let userAdditionalInfoLabel = userAdditionalPhoneNumber.label;

                return {
                    id: id,
                    type: 1, // Phone Number type
                    value: userAdditionalInfoValue,
                    label: userAdditionalInfoLabel,
                    valueAndLabel: `${userAdditionalInfoValue} ${userAdditionalInfoLabel ? `(${userAdditionalInfoLabel})` : ''}`,
                }
            }) : [];
        },
        userDateOfBirth() {
            let userDateOfBirth = this.user.dateOfBirth ? new Date(this.user.dateOfBirth) : null;
            return userDateOfBirth ? userDateOfBirth.toLocaleDateString("en-AU", {
                day: "numeric",
                month: "long",
                year: "numeric"
            }) : 'Not Provided';
        },
        userData() {
            return {
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                email: this.user.email,
                phoneNumber: this.user.phoneNumber,
                dateOfBirth: this.user.dateOfBirth,
            }
        },

        addressData() {
            return {
                street: this.address.street,
                suburb: this.address.suburb,
                state: this.address.state,
                postCode: this.address.postCode,
                country: this.address.country,
            }
        },

        selectedAddressData() {
            return {
                value: this.address.id,
                name: this.userFullAddress,
            };
        },
    },
    methods: {
        ...mapActions({
            resetCustomer: 'appCustomers/resetCustomer',
            getCustomer: 'appCustomers/getCustomer',
            putCustomer: 'appCustomers/putCustomer',
            putAvatar: 'appUsers/putAvatar',
            deleteUserAdditionalInfo: 'appUserAdditionalInfos/deleteUserAdditionalInfo',
            commitNotFoundRouteStatus: 'commitNotFoundRouteStatus',

            getLoyaltyPointStatus: 'appLoyaltyPoints/getLoyaltyPointStatus',
            getRemainingCreditedAmount : 'appPayments/getRemainingCreditedAmount'


        }),
        openImageUpdateModal() {
            document.querySelector('[data-target="#updateAvatarModal"]').click();
        },
        async uploadImage(imageBase64) {

            const payLoad = {
                id: this.user.id,
                data: {
                    avatar: imageBase64
                }
            }
            const response = await this.putAvatar(payLoad);
            if (response.status === 201 || response.status === 200) {
                this.loader(false);
                document.querySelector('[data-target="#updateAvatarModal"]').click();
                await this.getSingleCustomer(this.$route.params.id)
            }
            if (response && response.message) {
                this.showToastMessage(response);
            }
        },

        async getSingleCustomer(id) {
            let paramObj = {
                id: id,
                params: this.getCustomerParams,
            };

            await this.getCustomer(paramObj).then(async (response) => {

                if (response.status === 404) {
                    this.commitNotFoundRouteStatus(true);
                    return;
                }

                if (response.status === 200) {
                    this.id = this.customer.id ? this.customer.id : '';
                    this.type = this.customer.type ? this.customer.type : '';
                    this.status = this.customer.status ? this.customer.status : '';
                    this.referralCode = this.customer.referral_code ? this.customer.referral_code : '';
                    this.newsletterSubscription = this.customer.newsletter_subscription ? this.customer.newsletter_subscription : '';
                    this.createdAt = this.customer.created_at ? this.customer.created_at : '';

                    this.user.id = this.customer.user && this.customer.user.id ? this.customer.user.id : '';
                    this.user.firstName = this.customer.user && this.customer.user.first_name ? this.customer.user.first_name : '';
                    this.user.lastName = this.customer.user && this.customer.user.last_name ? this.customer.user.last_name : '';
                    this.user.email = this.customer.user && this.customer.user.email ? this.customer.user.email : '';
                    this.user.phoneNumber = this.customer.user && this.customer.user.phone_number ? this.customer.user.phone_number : '';
                    this.user.dateOfBirth = this.customer.user && this.customer.user.date_of_birth ? this.customer.user.date_of_birth : '';

                    this.user.avatar = this.customer.user && this.customer.user.avatar ? this.customer.user.avatar : '';

                    this.user.addresses = this.customer.user && this.customer.user.addresses ? this.customer.user.addresses : [];
                    this.user.userAdditionalEmails = this.customer.user && this.customer.user.userAdditionalEmails ? this.customer.user.userAdditionalEmails : [];
                    this.user.userAdditionalPhoneNumbers = this.customer.user && this.customer.user.userAdditionalPhoneNumbers ? this.customer.user.userAdditionalPhoneNumbers : [];

                    this.address.id = this.customer.address && this.customer.address.id ? this.customer.address.id : '';
                    this.address.street = this.customer.address && this.customer.address.street ? this.customer.address.street : '';
                    this.address.suburb = this.customer.address && this.customer.address.suburb ? this.customer.address.suburb : '';
                    this.address.state = this.customer.address && this.customer.address.state ? this.customer.address.state : '';
                    this.address.postCode = this.customer.address && this.customer.address.post_code ? this.customer.address.post_code : '';
                    this.address.country = this.customer.address && this.customer.address.country ? this.customer.address.country : '';

                    this.address.business.id = this.customer.address && this.customer.address.business && this.customer.address.business.id ? this.customer.address.business.id : '';
                    this.address.business.name = this.customer.address && this.customer.address.business && this.customer.address.business.name ? this.customer.address.business.name : '';
                }
                if (response.status === 404) {
                    console.log(this.$root);
                    this.$store.dispatch('commitNotFoundRouteStatus', true)
                }

                if (response && response.message && response.status !== 200) {
                    this.showToastMessage(response);
                }
            });

        },

        async updatedUserData(updatedUserData) {
            this.user.firstName = updatedUserData.userData.firstName;
            this.user.lastName = updatedUserData.userData.lastName;
            this.user.email = updatedUserData.userData.email;
            this.user.phoneNumber = updatedUserData.userData.phoneNumber;
            this.user.dateOfBirth = updatedUserData.userData.dateOfBirth;
        },

        async updateModalAddressData(updateModalAddressData) {
            this.address.id = updateModalAddressData.addressData.id;
            this.address.street = updateModalAddressData.addressData.street;
            this.address.suburb = updateModalAddressData.addressData.suburb;
            this.address.state = updateModalAddressData.addressData.state;
            this.address.postCode = updateModalAddressData.addressData.postCode;
            this.address.country = updateModalAddressData.addressData.country;

            if (this.type === 'Business') {
                this.address.business.id = updateModalAddressData.addressData.business.id;
                this.address.business.name = updateModalAddressData.addressData.business.name;
            }
        },

        async updateCustomerStatus(status) {
            let dataObj = {
                id: this.id,
                data: {
                    status: status,
                },
            };
            this.putCustomer(dataObj).then(async (response) => {
                if (response.status === 200) {
                    this.status = this.customer.status;
                    let toastObj = {
                        message: 'Customer Info Updated',
                        type: 'success'
                    };
                    this.showToastMessage(toastObj);
                    return;
                }
                this.showToastMessage(response);
            });
        },

        async updateCustomerNewsletterSubscriptionStatus(newsletterSubscription) {
            let dataObj = {
                id: this.id,
                data: {
                    newsletter_subscription: newsletterSubscription,
                },
            };
            this.putCustomer(dataObj).then(async (response) => {
                if (response.status === 200) {
                    this.newsletterSubscription = this.customer.newsletter_subscription;
                    let toastObj = {
                        message: 'Newsletter subscription status updated',
                        type: 'success'
                    };
                    this.showToastMessage(toastObj);
                    return;
                }
                this.showToastMessage(response);
            });
        },

        async updateAdditionalInfoModalDetailsAssigning(userAdditionalInfoObj) {
            this.userAdditionalInfoIdForUpdate = userAdditionalInfoObj.id;
            this.UserAdditionalInfoUpdateOrCreateModalType = userAdditionalInfoObj.type;
            this.userAdditionalInfoExistingDataValue = userAdditionalInfoObj.value;
            this.userAdditionalInfoExistingDataLabel = userAdditionalInfoObj.label;
        },

        async removeAdditionalInfo(userAdditionalInfoObj) {
            let id = userAdditionalInfoObj.id;
            this.deleteUserAdditionalInfo(id).then(async (response) => {
                if (response.status === 204) {
                    let toastObj = {
                        message: 'Additional Info Removed',
                        type: 'success'
                    };
                    this.showToastMessage(toastObj);

                    if (userAdditionalInfoObj.type === 0) {
                        this.user.userAdditionalEmails.splice(this.user.userAdditionalEmails.findIndex(userAdditionalInfo => userAdditionalInfo.id === userAdditionalInfoObj.id), 1);
                    }
                    if (userAdditionalInfoObj.type === 1) {
                        this.user.userAdditionalPhoneNumbers.splice(this.user.userAdditionalPhoneNumbers.findIndex(userAdditionalInfo => userAdditionalInfo.id === userAdditionalInfoObj.id), 1);
                    }

                    return;
                }
                this.showToastMessage(response);
            });
        },

        async addUserAdditionalInfoData(addUserAdditionalInfoData) {
            if (addUserAdditionalInfoData.UserAdditionalInfo.type === 'Email') {
                this.user.userAdditionalEmails.push(addUserAdditionalInfoData.UserAdditionalInfo);
            }
            if (addUserAdditionalInfoData.UserAdditionalInfo.type === 'Phone Number') {
                this.user.userAdditionalPhoneNumbers.push(addUserAdditionalInfoData.UserAdditionalInfo);
            }
        },

        async UpdateUserAdditionalInfoData(updateUserAdditionalInfoData) {
            if (updateUserAdditionalInfoData.UserAdditionalInfo.type === 'Email') {
                this.user.userAdditionalEmails[this.user.userAdditionalEmails.findIndex(userAdditionalInfo => userAdditionalInfo.id === updateUserAdditionalInfoData.UserAdditionalInfo.id)] = updateUserAdditionalInfoData.UserAdditionalInfo;
            }
            if (updateUserAdditionalInfoData.UserAdditionalInfo.type === 'Phone Number') {
                this.user.userAdditionalPhoneNumbers[this.user.userAdditionalPhoneNumbers.findIndex(userAdditionalInfo => userAdditionalInfo.id === updateUserAdditionalInfoData.UserAdditionalInfo.id)] = updateUserAdditionalInfoData.UserAdditionalInfo;
            }
        },

        getLoyaltyPointStatusHander(userId) {
            this.getLoyaltyPointStatus({
                id: userId
            });
        },
         getRemainingCreditedAmountHandler(userId){
           this.getRemainingCreditedAmount(userId);
        },

    },
    async mounted() {
        this.loader(true);
        const routeParams = await this.$route.params;
        await this.getSingleCustomer(routeParams.id);
        if (this.customer ?.user ?.id) {
             this.getLoyaltyPointStatusHander(this.customer ?.user ?.id);
             this.getRemainingCreditedAmountHandler(this.customer ?.user ?.id);
            

        }

        this.loader(false);
    },
    async beforeUnmount() {
        await this.resetCustomer();
    },
}
</script>

<style scoped>
p {
    margin: 0;
    padding: 0;
    font-weight: 600;
}

.update-image-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #5a8dee;
    border: 2px solid white;
    top: 79%;
    right: 10px;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.update-image-avatar:hover {
    background-color: #4173ce;
}

.update-image-avatar>i {
    font-size: 20px;
    z-index: 10;
    color: white;
}

@media only screen and (max-width: 576px) {
    .update-image-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #5a8dee;
        border: 2px solid white;
        top: 64%;
        left: 92px;
        right: 0;
        z-index: 10;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
}
</style>
